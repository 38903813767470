<template>
	<div class="container">
		<el-card class="box-card" style="min-height: auto">
			<div style="margin-bottom: 10px"><i class="card-line"></i>搜索服务</div>
			<div class="searchFor" v-loading="loading" >
				<el-form ref="search" label-width="100px" :model="searchFor">
					<el-row>
						<el-col :span="5">
							<el-form-item label="关键字：" prop="oldPassword">
								<el-input v-model="searchFor.searchValue" placeholder="请输入查询信息"  type="text" clearable />
							</el-form-item>
						</el-col>

						<el-col :span="7">
							<el-button type="primary" style="margin-left:20px;width: 100px;" @click="search">搜索</el-button>
							<el-button type="primary" style="margin-left:20px;width: 100px;" @click="clearInput">重置</el-button>
						</el-col>

					</el-row>
				</el-form>

				<!-- 报告列表展示 -->
				<el-collapse v-model="activeNames">
					<el-collapse-item v-for="item in serviceList" :key="item.id" :name="item.id">
						<template #title>
							<span v-html="item.currencyName"></span>
						</template>

						<div>
							<span v-html="item.currencyFiles"></span>
						</div>

					</el-collapse-item>
				</el-collapse>
				<el-pagination v-model:current-page="currentPage" v-model:page-size="pageSize"
					:page-sizes="[10, 20, 30, 40, 100]" :total="total" layout=" prev, pager, next, sizes,jumper"
					@size-change="searchSizeChange" @current-change="searchCurrentChange" />

			</div>
		</el-card>


	</div>

</template>

<script>
import { searchs } from './searchServicesMethods'
import { reactive, ref, toRefs } from 'vue'
export default {
	name: "searchServices",
	setup() {
		let activeNames = ref(['1'])
		let data = reactive({
			searchFor: {
				searchValue: '',
			},
			serviceList: [],
			total: '',
			currentPage: 1,
			pageSize: 10,
			loading: false 
		})
		// 	重置按钮
		let clearInput = () => {
			data.searchFor.searchValue = ''
		}
		// 搜索按钮
		let search = () => {
			searchs(data)
		}
		//分页条数切换
		let searchSizeChange = (val) => {
			console.log('分页条数切换', val);
			data.pageSize = val
			search()
		}
		//分页页数切换
		let searchCurrentChange = (val) => {
			console.log('页数切换', val);
			data.currentPage = val
			search()
		}
		return {
			...toRefs(data),
			activeNames,
			clearInput,
			search,
			searchSizeChange,
			searchCurrentChange
		};
	},
};
</script>

<style lang="less" scoped setup>
.el-main {
	padding: 20px 50px;
}

.container {
	width: 100%;
	min-height: 600px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: 0 auto;

	.el-form {
		margin-top: 15px;
		padding-top: 10px;
		min-height: 100px;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: 0 auto;
	}

	.card-line {
		display: inline-block;
		width: 3px;
		height: 13px;
		border-radius: 8px;
		margin-right: 10px;
		background: #faad14;
	}
}
</style>
